import React from "react";
import Modal from "react-bootstrap/Modal";
import Image from "components/common/utils/Image";
import VideoPlayer from "components/common/VideoPlayer";
import StructuredVideo from "components/common/meta/StructuredVideo";
import PlayBlack from "images/icons/play-black.svg";
import "./styles.sass";
import T from "types/index";
import { AirtableImage } from "components/common/utils/AirtableImage";

type Props = {
  videoRecord: T.Charles.YoutubeVideoRecord;
  showPlayIcon: boolean;
};
type State = {
  showModal: boolean;
};

class AirtableVideoThumbnail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleShow = () => {
    this.setState({ showModal: true });
  };

  buildYoutubeEmbedUrl = (id: string) => {
    return `https://www.youtube.com/embed/${id}?feature=oembed&rel=0&autoplay=1`
  }

  render() {
    const { videoRecord, showPlayIcon } = this.props;
    return (
      <>
        <StructuredVideo videoId={videoRecord.data.video_id} />
        <div className="video-thumbnail" onClick={this.handleShow}>
          <AirtableImage
            className="img-fluid video-thumbnail-image core-embed-image"
            image={videoRecord}
          />
          {showPlayIcon && (
            <div className="video-thumbnail-overlay">
              <PlayBlack width="80" />
            </div>
          )}
        </div>
        <Modal
          centered
          show={this.state.showModal}
          onHide={this.handleClose}
          size="xl"
        >
          <div className="expert-video-modal">
            <VideoPlayer video={this.buildYoutubeEmbedUrl(videoRecord.data.video_id)} />
          </div>
        </Modal>
      </>
    );
  }
}

export { AirtableVideoThumbnail };
