import React, { useEffect, useState } from "react";
import Image from "../utils/Image";
import "./styles.sass";
import VideoThumbnail from "../VideoThumbnail";
import cx from "classnames";
import { chunk } from "utils/lodashExtracts";
import { AirtableImages } from "components/common/utils/AirtableImage";
import { AirtableMarkdown } from "components/common/utils/AirtableMarkdown";
import T from "types/index";
import { AirtableVideoThumbnail } from "templates/Pathologies/common/AirtableVideoThumbnail";

type MyCarouselProps<ItemType> = {
  chunkOnMobile?: boolean;
  scrollGap?: number;
  prefix: string;
  data: ItemType[];
  renderItem: (item: ItemType) => React.ReactNode;
};

const MyCarousel = <ItemType,>({
  data,
  chunkOnMobile = false,
  scrollGap = 1,
  prefix,
  renderItem,
}: MyCarouselProps<ItemType>) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  /*  tried to generate random prefix for unique ID
    seems it's not working due to SSR
    const [prefix] = useState(_uniqueId('prefix-'));
   */
  let itemChunks: ItemType[][] = chunk(data, scrollGap);
  let counter = itemChunks.length;

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  function slideToNext() {
    const carousel = document.getElementById(`${prefix}-scientist-carousel`);
    const nextSlide = document.getElementById(
      `${prefix}-chunk-${carouselIndex + 1}`
    );
    const chunk0 = document.getElementById(`${prefix}-chunk-0`);
    if (carousel && nextSlide && chunk0) {
      const offsetX = nextSlide.offsetLeft - chunk0.offsetLeft;
      carousel.scrollTo(offsetX, 0);
      if (iOS() === true) {
        setCarouselIndex(carouselIndex + 1);
      }
    }
  }

  function slideToPrev() {
    const carousel = document.getElementById(`${prefix}-scientist-carousel`);
    const prevSlide = document.getElementById(
      `${prefix}-chunk-${carouselIndex - 1}`
    );
    const chunk0 = document.getElementById(`${prefix}-chunk-0`);
    if (carousel && prevSlide && chunk0) {
      const offsetX = prevSlide.offsetLeft - chunk0.offsetLeft;
      carousel.scrollTo(offsetX, 0);
      if (iOS() === true) {
        setCarouselIndex(carouselIndex - 1);
      }
    }
  }

  function setCurrentSlide(e: any) {
    const element = e.target;
    const x = element.scrollLeft;
    const children = element.childNodes;
    const array = Array.from(children);
    const carouselRemoteWrapper = document.getElementById(
      `${prefix}-carousel-remote-wrapper`
    );
    const bulletPoint = carouselRemoteWrapper
      ? Array.from(carouselRemoteWrapper.childNodes)
      : [];
    array.forEach((child: any, i) => {
      if (
        x > child.offsetLeft - children[0].offsetLeft - 16 &&
        x < child.offsetLeft + child.offsetWidth - children[0].offsetLeft - 16
      ) {
        setCarouselIndex(i);
      }
    });
    bulletPoint.forEach((bullet: any, i) => {
      if (carouselIndex === i && bullet.style.backgroundColor !== "black") {
        bullet.style.backgroundColor = "black";
      } else if (
        bullet.style.backgroundColor !== "#C4C4C4" &&
        carouselIndex !== i
      ) {
        bullet.style.backgroundColor = "#C4C4C4";
      }
    });
  }

  useEffect(() => {
    const addClassToElementWithId = (elementId: string, className: string) => {
      const element = document.getElementById(elementId);
      if (element != null) {
        element.classList.add(className);
      }
    };
    const removeClassFromElementWithId = (
      elementId: string,
      className: string
    ) => {
      const element = document.getElementById(elementId);
      if (element != null) {
        element.classList.remove(className);
      }
    };
    if (carouselIndex === 0) {
      addClassToElementWithId(
        `${prefix}-carousel-sc-prev`,
        "carousel-button-tag-disabled"
      );
    } else if (carouselIndex === counter - 1) {
      addClassToElementWithId(
        `${prefix}-carousel-sc-next`,
        "carousel-button-tag-disabled"
      );
    } else {
      removeClassFromElementWithId(
        `${prefix}-carousel-sc-prev`,
        "carousel-button-tag-disabled"
      );
      removeClassFromElementWithId(
        `${prefix}-carousel-sc-next`,
        "carousel-button-tag-disabled"
      );
    }
    if (!window.matchMedia("(min-width: 1100px)").matches) {
      scrollGap = 1;
      itemChunks = chunk(data, scrollGap);
      counter = itemChunks.length;
    }
  });

  return (
    <>
      <div
        id={`${prefix}-scientist-carousel`}
        className={cx("landing-scientist-section-carousel", {
          "landing-scientist-section-video-carousel": chunkOnMobile,
        })}
        onScroll={setCurrentSlide}
      >
        {!chunkOnMobile
          ? data.map((item, i) => (
              <div key={i} className="scientist-section-carousel-chunk">
                <div
                  id={`${prefix}-chunk-${i}`}
                  className="scientist-section-carousel-card"
                >
                  {renderItem(item)}
                </div>
              </div>
            ))
          : itemChunks.map((chunk, i) => (
              <div
                id={`${prefix}-chunk-${i}`}
                className="scientist-section-carousel-chunk"
              >
                {chunk.map((item, j) => (
                  <div className="scientist-section-carousel-card" key={j}>
                    {renderItem(item)}
                  </div>
                ))}
              </div>
            ))}
      </div>
      <div
        className={cx("landing-scientist-section-carousel-remote", {
          "carousel-remote-video": chunkOnMobile,
        })}
      >
        <div
          id={`${prefix}-carousel-sc-prev`}
          className="carousel-button-tag carousel-button-tag-disabled"
          onClick={slideToPrev}
        >
          <Image
            filename="new-images/tag.svg"
            alt="fleche-haut-picto"
            title="fleche haut picto"
          />
        </div>
        <div
          id={`${prefix}-carousel-remote-wrapper`}
          className="carousel-remote-bullet-point-wrapper"
        >
          {itemChunks.map((_e: any, i: number) => (
            <div className="carousel-remote-bullet-point" key={i} />
          ))}
        </div>
        <div
          id={`${prefix}-carousel-sc-next`}
          className="carousel-button-tag"
          onClick={slideToNext}
        >
          <Image
            filename="new-images/tag.svg"
            alt="fleche-haut-picto"
            title="fleche haut picto"
          />
        </div>
      </div>
    </>
  );
};

export default MyCarousel;

export const videoRenderer = (video: any) => (
  <VideoThumbnail className="w-100" data={video} showPlayIcon />
);

export const airtableVideosRenderer = (item: T.Charles.YoutubeVideoRecord) => (
  <AirtableVideoThumbnail videoRecord={item} showPlayIcon />
);

export const imageRenderer = (obj: any) => (
  <>
    <Image
      filename={obj.pictures.regular}
      className="scientist-section-carousel-image"
      alt={obj.pictures.alt}
      title={obj.pictures.title}
    />
    <div className="scientist-section-carousel-text-div">
      <h3 className="h4 text-left">{obj.fullName}</h3>
      <div>{obj.description}</div>
    </div>
  </>
)

export const scientistRenderer = (obj: any) => (
  <>
    <Image
      filename={obj.pictures.regular}
      className="scientist-section-carousel-image"
      alt={obj.pictures.alt}
      title={obj.pictures.title}
    />
    <div className="scientist-section-carousel-text-div">
      <div className="h4 text-left">{obj.fullName}</div>
      <div>{obj.description}</div>
    </div>
  </>
)

export const airtableItemRenderer = (item: T.Charles.ItemRecord) => (
  <>
    <AirtableImages
      images={item.data.image}
      className="scientist-section-carousel-image"
    />
    <div className="scientist-section-carousel-text-div">
      <h3 className="h4 text-left"><AirtableMarkdown markdown={item.data.title} /></h3>
      <AirtableMarkdown markdown={item.data.text} />
    </div>
  </>
);

export const airtableTreatmentRenderer = (treatment: T.Charles.TreatmentRecord) => (
  <>
    <AirtableImages
      images={treatment.data.image}
      className="scientist-section-carousel-image"
    />
    <div className="scientist-section-carousel-text-div">
      <h3 className="h4 text-left">{treatment.data.name}</h3>
      <div>{treatment.data.description}</div>
    </div>
  </>
);

export const airtableScientistRenderer = (scientist: T.Charles.ScientistRecord) => (
  <>
    <AirtableImages
      images={scientist.data.image_regular}
      className="scientist-section-carousel-image"
    />
    <div className="scientist-section-carousel-text-div">
      <div className="h4 text-left">{scientist.data.full_name}</div>
      <div>{scientist.data.description}</div>
    </div>
  </>
);
