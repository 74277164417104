import React from 'react';
import { isUndefined } from 'utils/lodashExtracts';
import Image from '../common/utils/Image';
import Cta from 'components/common/Cta';
import MyCarousel, { scientistRenderer } from 'components/common/MyCarousel';
import './styles.sass';
import Container from 'react-bootstrap/Container';
import cx from 'classnames';

const ScientistSection = ({ version, scientists, startSlug }) => {
  const actualStartSlug = !isUndefined(startSlug) ? startSlug : '/consulter/';
  return (
    <div className="landing-scientist-section overflow-hidden">
      {version === 'pathology'
        ? (<div id="scientist-anchor" className="pathology-scientist-anchor pathology-anchor" />)
        : null}
      <h2 className="landing-scientist-section-h2">{scientists.title}</h2>
      {scientists.team.length > 1
        ? (
          <>
            <div className="landing-scientist-section-subtitle">
              Une équipe pluridisciplinaire composée de médecin experts de leurs domaines
            </div>
            <Container className="my-carousel-container">
              <MyCarousel
                data={scientists.team}
                prefix="scientist"
                renderItem={scientistRenderer}
              />
            </Container>
          </>
        )
        : scientists.team.map((obj, i) => (
          <div key={i} className="one-doctor-chunk">
            <div id={i} className="one-doctor-card">
              <Image
                filename={obj.pictures.regular}
                className="one-doctor-image"
                title={obj.pictures.title}
                alt={obj.pictures.alt}
              />
              <div className="one-doctor-text-div">
                <div className="h4 text-left">{obj.fullName}</div>
                <div className="mb-4 mb-lg-0">{obj.description}</div>
                <div className="landing-scientist-cta mt-auto">
                   <Cta path={actualStartSlug} text={scientists.isSexologist ? "Consultez un sexologue": "Consultez un médecin"} className="new-primary-cta" />
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className={cx('landing-scientist-section-cta-wrapper', { 'home-scientist-section-cta-wrapper': version !== 'pathology' })}>
        {version !== 'pathology'
          ? (
            <Cta path="/approche-medicale/" text="Découvrir nos médecins" className="new-secondary-black-cta landing-scientist-cta mb-3 mb-md-0" />
          ) : null}
        {scientists.team.length > 1  &&
           <Cta path={actualStartSlug} text={scientists.isSexologist ? "Consultez un sexologue": "Consultez un médecin"} className="new-primary-cta landing-scientist-cta" /> }
      </div>
    </div>
  );
};

export default ScientistSection;
