import React, { useEffect, useRef, useState } from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import Section from 'components/common/Section';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Layout } from 'components/common';
import StructuredWebPage from 'components/common/meta/StructuredWebPage';
import MyCarousel, { imageRenderer } from 'components/common/MyCarousel';
import data from './data.js';
import { doctors } from 'components/common/data/doctors';
import cx from 'classnames';
import { scientists } from 'components/Landing/data';

import ScientistSection from '../ScientistSection';

import BlueUnderline from 'images/new-images/blue-pen-underline.svg';
import './styles.sass';
import Image from '../common/utils/Image';
import TestimonySection from 'components/TestimonySection';
import HowItWorksSection from 'components/HowItWorksSection';
import { Modal } from 'react-bootstrap';

const IndexPage = () => {
  const [specialty, setSpecialty] = useState('sexual-health');
  const [teamLimit, setTeamLimit] = useState(4);
  const [doctorDetails, setDoctorDetails] = useState(doctors[0]);
  const doctorPopup = useRef(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function changeSpecialty(e) {
    setSpecialty(`${e.target.id}`);
  }

  function showDoctorDetails(doc) {
    setDoctorDetails(doc);
    handleShow();
  }
  const title = "Notre approche médicale"

  return (
    <Layout
      showLinksInFooter={false}
    >
      <CharlesMeta
        title={data.metaData.title}
        description={data.metaData.description}
      />
      <StructuredWebPage
        title={title}
        description={data.metaData.description}
      />
      <div id="science-page" className="science-main-div">
        <Section className="science-top-section">
          <Container className="d-flex flex-column align-items-center">
            <h1 className="">{title}</h1>
            <div className="blue-underline-wrapper">
              <BlueUnderline className="blue-underline" />
            </div>
            <div className="science-top-section-description">
              Notre comité scientifique rassemble des médécins experts en sexologie, sommeil, digestion, nutrition et dermatologie
            </div>
          </Container>
        </Section>
        <Section className="science-explanation-section">
          <Container className="">
            <Row className="flex-row-reverse justify-content-xl-between">
              <Col xs={12} xl={6}>
                <Image
                  filename="new-images/scientists-mosaic.png"
                  alt="mosaique-des-medecins"
                  title="mosaique des medecins"
                  className="science-explanation-mosaic d-xl-none"
                />
                <Image
                  filename="new-images/desktop-scientists-mosaic.png"
                  alt="mosaique-des-medecins-desktop"
                  title="mosaique des medecins desktop"
                  className="science-explanation-mosaic d-none d-xl-block"
                />
              </Col>
              <Col xs={12} xl={5} className="science-explanation-text-col">
                <h2>
                  Une plateforme créée et développée par des médecins experts
                </h2>
                <div className="scientist-explanation-important-description">
                Le comité scientifique détermine notre contenu médical, nos protocoles,
                 nos plans de traitements et le parcours de soin de nos patients de bout en bout.
                </div>
                <div className="scientist-explanation-description">
                Ils forment continuellement les médecins spécialistes consultants sur Charles
                 aux dernières solutions thérapeutiques dans chaque domaine. Ils coordonnent
                  l'assistance médicale pour vous assurer un accompagnement sûr et de qualité
                   tout au long de votre expérience sur la plateforme.
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="medical-protocol-section">
          <Container>
            <h2>Comment travaillons nous avec notre comité scientifique ?</h2>
            <div className="medical-protocol-description">
            Nos directeurs scientifiques accompagnent la Clinique Digitale au quotidien
            </div>
            <Row className="medical-protocol-row justify-content-center">
              <Col xs={12} md={8} xl={4} className="medical-protocol-step">
                <Image
                  filename="new-images/stethoscope.jpeg"
                  alt="comprendre-les-pathologies-masculines"
                  title="comprendre les pathologies masculines"
                  className="medical-protocol-step-image"
                />
                <h3>Comprendre les pathologies</h3>
                <div>
                  Notre comité scientifique nous aide à mieux comprendre les
                  pathologies et à appréhender toutes leurs
                  dimensions et leurs complexités, avec l'état de la recherche
                  actuelle et les dernières avancées de le recherche dans chaque
                  domaine.
                </div>
              </Col>
              <Col xs={12} md={8} xl={4} className="medical-protocol-step">
                <Image
                  filename="new-images/men-w-earphone.jpeg"
                  alt="plans-de-traitements-adaptes"
                  title="plans de traitements adaptes"
                  className="medical-protocol-step-image"
                />
                <h3>Créer les plans de traitements adaptés</h3>
                <div>
                  Pour chaque pathologie notre comité scientifique crée des
                  plans de traitements médicaux innovants, efficaces et
                  personnalisables pour chaque patient. Ces plans peuvent faire
                  appel à plusieurs techniques ou protocoles médicaux
                  complémentaires qui visent à une
                  résolution durable des pathologies.
                </div>
              </Col>
              <Col xs={12} md={8} xl={4} className="medical-protocol-step">
                <Image
                  filename="new-images/apple-desk.jpeg"
                  alt="formation-des-medecins-en-continue"
                  title="formation des medecins en continue"
                  className="medical-protocol-step-image"
                />
                <h3>Former les médecins en continu</h3>
                <div>
                  Notre comité scientifique est à la pointe des évolutions
                  médicales et forment en continu les
                  médecins consultants sur la plateforme aux dernières avancées
                  thérapeutiques ainsi qu'aux exigences de la consultation à
                  distance, pour assurer une prise en charge optimale
                  à nos patients.
                </div>
              </Col>
            </Row>
            <div className="div-as-hr" />
          </Container>
        </Section>
        <ScientistSection scientists={scientists} />
        <Section className="treatments-section d-xl-none">
          <Container>
            <div className="h2">Nos plans de traitement</div>
            <div className="treatments-description">
              Les plans de traitement des médecins de Charles combinent
              plusieurs techniques médicales complémentaires pour une efficacité
              maximale et durable.
            </div>
          </Container>
          <Container className="my-carousel-container science-treatments-carousel">
            <MyCarousel
              data={data.treatments.list}
              prefix="science-treatments"
              renderItem={imageRenderer}
            />
          </Container>
        </Section>
        <HowItWorksSection version="science" hideNavbarOverSection />
        <Section className="full-medical-team-section">
          <Container className="">
            <div className="div-as-hr" />
            <h2>Notre équipe de médecins</h2>
            <div className="full-medical-team-description">
              Une équipe pluridisciplinaire composée de médecin experts de leurs
              domaines
            </div>
          </Container>
          <Container>
            <Row className="full-medical-team justify-content-center">

              {doctors.sort()
                .map((doc, i) => (
                  <Col
                    xs={12}
                    md={8}
                    lg={6}
                    xl={3}
                    className={cx('full-medical-team-card d-xl-block', {
                      'd-none': i > teamLimit,
                    })}
                    key={i}
                  >
                    <Image
                      filename={doc.pictures.regular}
                      alt={doc.pictures.alt}
                      title={doc.pictures.title}
                      className="full-medical-team-card-image"
                    />
                    <h3 className="text-left h4">{doc.fullName}</h3>
                    <div className="full-medical-team-description">
                      {doc.practitionerInfo.speciality}
                    </div>
                    <div
                      className="full-medical-team-link"
                      onClick={() => showDoctorDetails(doc)}
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      <div>En savoir plus</div>
                      <Image
                        filename="new-images/arrow-link.svg"
                        alt="fleche-droite-fine-picto"
                        title="fleche  droite fine picto"
                      />
                    </div>
                  </Col>
                ))}
            </Row>
            {teamLimit === 4 ? (
              <div
                className="new-secondary-black-cta show-doctors d-xl-none"
                onClick={() => setTeamLimit(doctors.length)}
              >
                Voir plus de spécialistes
              </div>
            ) : (
              <div
                className="new-secondary-black-cta show-doctors d-xl-none"
                onClick={() => setTeamLimit(4)}
              >
                Voir moins de spécialistes
              </div>
            )}
          </Container>
        </Section>
        <TestimonySection className="full-team-testimony-section" />
        <Modal
          show={show}
          ref={doctorPopup}
          onHide={handleClose}
          dialogClassName="science-doctor-popup"
          fullScreen="md-down"
        >
          <div className="exit-doctor-popup" onClick={handleClose}>
            <Image filename="new-images/cross-simple.svg"
            alt="cross-simple"
            title="cross simple"/>
          </div>
          <Image
            className="science-doctor-popup-image"
            filename={doctorDetails.pictures.regular}
            title={doctorDetails.pictures.title}
            alt={doctorDetails.pictures.alt}
          />

          <div className="h3">{doctorDetails.fullName}</div>
          <div className="h4 text-left">{doctorDetails.practitionerInfo.speciality}</div>
          <div className="">
            {doctorDetails.practitionerInfo.educationAndTitles.map((title, i) =>
            <li>{title}</li>
            )}</div>

        </Modal>
      </div>
    </Layout>
  );
};

export default IndexPage;
